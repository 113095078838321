import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const GoneForeverPage = () => (
  <Layout>
    <Seo title="410: Permanently Deleted" />
	<div className="main--centerwrapper">
		<StaticImage
		src="../images/photos/pressRes/rebecca1.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>410: Permanently Deleted</h3>
    <p>That route was permanently deleted, i.e., it no longer exists.</p>
	<p>
		<Link to="/">Go to the homepage</Link>
	</p>
  </Layout>
);

export default GoneForeverPage;
